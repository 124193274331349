import React from 'react'
import { connect } from 'react-redux'


class ContentType3 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cardID: this.props.cardID,
			playHighlightVideo: null,
			error: {},
		}
	}

	componentDidMount() {
		// Send can open notification //
	}
	render() {
		let { contentInfo } = this.props

		let { playHighlightVideo } = this.state

		let videoUrl, previewPhoto;

		try {
			videoUrl = contentInfo.video
		} catch (error) { }
		try {
			previewPhoto = contentInfo.photo
		} catch (error) { }

		// return (
		// 	<div>Kaj hoi na</div>
		// )

		return (
			videoUrl != null ?
				<div className="content-type-3">
					<div className="video-preview">
						{(() => {
							if (playHighlightVideo != null && videoUrl) {
								return (
									<video controls key={`video_tab${contentInfo.ID}`} autoPlay={true} playsinline="playsinline">
										<source src={videoUrl} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								);
							} else {
								return (
									<div className="preview-photo" onClick={() => { this.setState({ playHighlightVideo: true }) }}>
										<img src={previewPhoto} alt="" />
										{(() => {
											if (videoUrl) {
												return (
													<div className="play-button">
														<i className="fas fa-play"></i>
													</div>
												)
											}
										})()}
									</div>
								);
							}
						})()}
					</div>
				</div>
				: ''
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
})
const mapDispatchToProps = ({
})
export default connect(mapStateToProps, mapDispatchToProps)(ContentType3)
