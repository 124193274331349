import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { sendNotification } from '../../store/actions/cardActions'

class TabTypeSMM extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cardID: this.props.match.params.cardID,
			error: {},
		}
	}

	componentDidMount() {
		// Send can open notification //
	}
	render() {
		let { socialMedia } = this.props

		let facebook, twitter, instagram, youtube, snapchat, tiktok, linkedin;
		let smmEnabled = false;

		try { facebook = socialMedia.facebook.sm_status != 0 ? socialMedia.facebook.sm_link : null; } catch (error) { }
		try { twitter = socialMedia.twitter.sm_status != 0 ? socialMedia.twitter.sm_link : null; } catch (error) { }
		try { instagram = socialMedia.instagram.sm_status != 0 ? socialMedia.instagram.sm_link : null; } catch (error) { }
		try { youtube = socialMedia.youtube.sm_status != 0 ? socialMedia.youtube.sm_link : null; } catch (error) { }
		try { snapchat = socialMedia.snapchat.sm_status != 0 ? socialMedia.snapchat.sm_link : null; } catch (error) { }
		try { tiktok = socialMedia.tiktok.sm_status != 0 ? socialMedia.tiktok.sm_link : null; } catch (error) { }
		try { linkedin = socialMedia.linkedin.sm_status != 0 ? socialMedia.linkedin.sm_link : null; } catch (error) { }

		if (facebook || twitter || instagram || youtube || snapchat || tiktok || linkedin) {
			smmEnabled = true;
		}

		return (
			smmEnabled && <div className="single-tab type-SMM">
				<div className="all-links">
					{facebook && <a
						onClick={() => { this.sendNotification("Tapped on Facebook button") }}
						className="smm-icon" href={facebook} target="_blank" rel="noopener noreferrer"> <i className="fab fa-facebook-f"></i> </a>}
					{twitter && <a
						onClick={() => { this.sendNotification("Tapped on Twitter button") }}
						className="smm-icon" href={twitter} target="_blank" rel="noopener noreferrer"> <i className="fab fa-twitter"></i> </a>}
					{instagram && <a
						onClick={() => { this.sendNotification("Tapped on Instagram button") }}
						className="smm-icon" href={instagram} target="_blank" rel="noopener noreferrer"> <i className="fab fa-instagram"></i> </a>}
					{youtube && <a
						onClick={() => { this.sendNotification("Tapped on Youtube button") }}
						className="smm-icon" href={youtube} target="_blank" rel="noopener noreferrer"> <i className="fab fa-youtube"></i> </a>}
					{snapchat && <a
						onClick={() => { this.sendNotification("Tapped on Snapchat button") }}
						className="smm-icon" href={snapchat} target="_blank" rel="noopener noreferrer"> <i className="fab fa-snapchat"></i> </a>}
					{tiktok && <a
						onClick={() => { this.sendNotification("Tapped on Tiktok button") }}
						className="smm-icon" href={tiktok} target="_blank" rel="noopener noreferrer"> <i className="fab fa-tiktok"></i> </a>}
					{linkedin && <a
						onClick={() => { this.sendNotification("Tapped on Linkedin button") }}
						className="smm-icon" href={linkedin} target="_blank" rel="noopener noreferrer"> <i className="fab fa-linkedin"></i> </a>}
				</div>
			</div>
		)
	}


	sendNotification(title) {
		let { cardID } = this.state

		let postData = {
			"card": cardID,
			"type": "custom",
			"custom_title": title,
			"type_id": cardID,
		};
		this.props.sendNotification(postData)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
})
const mapDispatchToProps = ({
	sendNotification,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabTypeSMM))
