import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import InputMask from 'react-input-mask';

import { sendNotification, insertTempProspector } from '../store/actions/cardActions'

class GuestForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			user: '',
			cardID: this.props.match.params.cardID,
			page: 1,
			password: '',
			disableClass: 'display-none',
			error: {},
			highlighVideo: null,
			highlightPreview: null,
			allowForm: true,
		}
	}

	focus() {
		this.refs.refForFirstName.focus();
	}
	componentDidMount() { }

	_changeHandler = event => {
		console.log(event.target.name);
		console.log(event.target.value);
		this.setState({
			[event.target.name]: event.target.value
		})
	}
	_handleSubmit = async event => {
		event.preventDefault()

		let { firstName, lastName, email, phone, cardID, addressA,
			addressB,
			city,
			state,
			zip, } = this.state
		var guestUser = {
			firstName, lastName, email, phone, addressA,
			addressB,
			city,
			state,
			zip,
		}

		// set data to local store
		localStorage.setItem('guestUser', JSON.stringify(guestUser))

		// update loading state
		this.setState({ isLoading: true })

		await this.props.insertTempProspector({
			cardID, firstName, lastName, email, phone, addressA,
			addressB,
			city,
			state,
			zip
		})

		this.setState({ isLoading: true })
		this._disableForm(true);

	}

	sendAnonymousNotification = async (event) => {
		if (event) {
			event.preventDefault()
		}

		// set data to local store
		localStorage.setItem('noNeedProspector', "true")
		this._disableForm(true);

		let { cardID } = this.state
		// Send card open notification //
		this.props.sendNotification({
			"card": cardID,
			"type": "card",
			"type_id": cardID,
		});
	}
	_disableForm(willClose) {
		if (willClose) {
			this.setState({ allowForm: false })
		}
	}

	componentWillMount() {
		var that = this;
		setTimeout(function () {
			that.setState({
				disableClass: ''
			});
			that.focus();
		}, that.props.wait);
	}

	render() {
		let { cardState, pageData, auth, tabInfo, prosState } = this.props
		let { firstName,
			lastName,
			email,
			phone,
			allowForm,
			isLoading,
			addressA,
			addressB,
			city,
			state,
			zip,
			disableClass } = this.state

		let { selectedCard } = cardState
		let { card_meta } = selectedCard
		let hideContactForm;

		try {
			hideContactForm = (card_meta.contactFormRequired !== "0") ? false : true;
		} catch (error) { }
		try {
			if (hideContactForm !== true) {
				allowForm = prosState.status === 'ready' ? false : true;
			}
		} catch (error) {

		}
		let com_tem_title;
		try {
			com_tem_title = card_meta.com_tem_title;
		} catch (error) {

		}



		return (
			<div>
				{(() => {
					if (allowForm) {
						return (
							<div className={`guest-form ${disableClass}`}>
								<div className="area-form">
									<form onSubmit={this._handleSubmit}>
										<div className="custom-title">{com_tem_title}</div>
										<div className="form-header">
											<div className="header-title">Enter your information</div>
											{hideContactForm === true && <div className="header-close" onClick={this.sendAnonymousNotification}>X</div>}
											<div className="header-submit">
												{isLoading
													? <button type="button">Thank You</button>
													: <button type="submit">Continue</button>
												}
											</div>
										</div>
										<div className="form-fields">
											{(() => {
												if (card_meta.contactFormItemFirstName !== '0') {
													return <div className="single-filed">
														<label htmlFor="guest_firstName" className="filed-label">First name</label>
														<input type="text" id="guest_firstName" name="firstName" className="filed-input"
															ref="refForFirstName"
															value={firstName}
															onChange={this._changeHandler} required />
													</div>
												}
											})()}

											{(() => {
												if (card_meta.contactFormItemLastName !== '0') {
													return <div className="single-filed">
														<label htmlFor="guest_lastName" className="filed-label">Last name</label>
														<input type="text" id="guest_lastName" name="lastName" className="filed-input"
															value={lastName}
															onChange={this._changeHandler} required />
													</div>
												}
											})()}
											{(() => {
												if (card_meta.contactFormItemEmail !== '0') {
													return <div className="single-filed">
														<label htmlFor="guest_email" className="filed-label">Email</label>
														<input type="email" id="guest_email" name="email" className="filed-input"
															value={email}
															onChange={this._changeHandler} required />
													</div>
												}
											})()}
											{(() => {
												if (card_meta.contactFormItemPhone !== '0') {
													return <div className="single-filed">
														<label htmlFor="guest_Phone" className="filed-label">Phone</label>
														<InputMask
															className="filed-input"
															name="phone"
															id="guest_Phone"
															onChange={this._changeHandler}
															value={phone}
															type="tel"
															required
														// mask="(999) 999-9999" maskChar={null}
														/>
													</div>
												}
											})()}
											{(() => {
												if (card_meta.contactFormItemAddressA === '1') {
													return <div className="single-filed">
														<label htmlFor="guest_addressA" className="filed-label">Address 1</label>
														<input type="text" id="guest_addressA" name="addressA" className="filed-input"
															autoFocus={true}
															value={addressA}
															onChange={this._changeHandler} required />
													</div>
												}
											})()}
											{(() => {
												if (card_meta.contactFormItemAddressB === '1') {
													return <div className="single-filed">
														<label htmlFor="guest_addressB" className="filed-label">Address 2</label>
														<input type="text" id="guest_addressB" name="addressB" className="filed-input"
															autoFocus={true}
															value={addressB}
															onChange={this._changeHandler} required />
													</div>
												}
											})()}
											{(() => {
												if (card_meta.contactFormItemCity === '1') {
													return <div className="single-filed">
														<label htmlFor="guest_city" className="filed-label">City</label>
														<input type="text" id="guest_city" name="city" className="filed-input"
															autoFocus={true}
															value={city}
															onChange={this._changeHandler} required />
													</div>
												}
											})()}
											{(() => {
												if (card_meta.contactFormItemState === '1') {
													return <div className="single-filed">
														<label htmlFor="guest_state" className="filed-label">State</label>
														<input type="text" id="guest_state" name="state" className="filed-input"
															autoFocus={true}
															value={state}
															onChange={this._changeHandler} required />
													</div>
												}
											})()}
											{(() => {
												if (card_meta.contactFormItemZip === '1') {
													return <div className="single-filed">
														<label htmlFor="guest_zip" className="filed-label">Zip</label>
														<input type="text" id="guest_zip" name="zip" className="filed-input"
															autoFocus={true}
															value={zip}
															onChange={this._changeHandler} required />
													</div>
												}
											})()}
										</div>
									</form>
								</div>
							</div>)
					}
				})()}
			</div>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	cardState: state.card,
	prosState: state.prospector,
})
const mapDispatchToProps = ({
	sendNotification,
	insertTempProspector
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GuestForm))
