import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import imageToBase64 from 'image-to-base64/browser';


import { loadCardDetails, sendNotification } from '../store/actions/cardActions'
import TabType1 from '../components/card/tabTypes1'
import TabType2 from '../components/card/tabTypes2';
import TabTypeSMM from '../components/card/tabTypeSMM';

import GuestForm from '../components/guestForm'

import setPageData from '../store/actions/setPageData'

import logoImage from '../assets/images/logo.png'
import VCard from 'vcard-creator'
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";


const ref = React.createRef();

class cardDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: '',
			cardID: this.props.match.params.cardID,
			page: 1,
			password: '',
			error: {},
			highlighVideo: null,
			highlightPreview: null,
			currentOpenTab: null,
		}
	}

	static getDerivedStateFromProps(nextProps, prevProps) {
		if (JSON.stringify(nextProps.cardState) !== JSON.stringify(prevProps.cardState)) {
			// console.log(this);
			// this._createRefs()
			// _createRefs()
			// setState({ password: 'ami' })

			return;
		}
		return;
	}

	setHighlighPreview() {
		this.setState({ highlighVideo: "https://firebasestorage.googleapis.com/v0/b/sap-app-8318e.appspot.com/o/video_thumbnail%2Fimage_cropper_A6C402A8-48C3-4341-8B77-7E27E6259C1F-10146-000008F8D003E8A9.jpg?alt=media&token=33d20508-53fb-4263-b498-425f87439385" })
	}
	setHighlighVideo(videoID) {
		var videoURL = "https://firebasestorage.googleapis.com/v0/b/sap-app-8318e.appspot.com/o/tab_video%2Ftrim.82893A48-55F4-4814-BD15-822CE6EA8439.MOV?alt=media&token=8159e2d6-2e1e-4b62-b565-cba307b82a25&ask" + videoID;
		this.setHighlighPreview()
		this.setState({ highlighVideo: videoURL })
	}

	loadCardDetails() {
		let { cardID } = this.state
		this.props.loadCardDetails({ ID: cardID })
	}
	sendNotification(postData) {
		this.props.sendNotification(postData)
	}

	componentDidMount() {
		this.props.setPageData({
			title: 'Invoice Details',
			pageID: 'invoice'
		})

		let { cardID } = this.state

		// Send card open notification //
		this.sendNotification({
			"card": cardID,
			"type": "card",
			"type_id": cardID,
		});

		this.loadCardDetails()
	}
	componentDidUpdate() { }

	render() {
		let tabColor = "3072dd";
		let { cardState, pageData, auth } = this.props
		let { page, currentOpenTab } = this.state
		let { user: authUser } = auth

		let hideProfilePhoto, hideCoverPhoto;

		let { cardID, highlighVideo, highlightPreview } = this.state
		let { selectedCard } = cardState
		// console.log(selectedCard)

		let shareCardButton = true;
		let tempProspector = true;
		let noNeedProspector = false;
		let contactFormOpenDelay;
		try {
			tabColor = selectedCard.card_meta.tab_color;
		} catch (error) {
			// console.log(error);
		}

		// Get profile & cover photo status
		try {
			if (selectedCard.card_meta) {
				contactFormOpenDelay = parseInt(selectedCard.card_meta.contactFormOpenDelay);
				contactFormOpenDelay = contactFormOpenDelay ? contactFormOpenDelay * 1000 : 1
			}
		} catch (error) { }

		// Getting permissions
		try {
			// console.log(selectedCard);
			if (selectedCard.permissions) {
				shareCardButton = selectedCard.permissions.shareButton !== '0' ? true: false;
			}
		} catch (error) { }

		try {
			hideProfilePhoto = (selectedCard.card_meta.hideProfilePhoto === "1") ? true : false;
		} catch (error) { }
		try {
			hideCoverPhoto = (selectedCard.card_meta.hideCoverPhoto === "1") ? true : false;
		} catch (error) { }
		// End Get profile & cover photo status

		// Start of no need form section
		try {
			noNeedProspector = (selectedCard.card_meta.hideContactForm === "1") ? true : false;
		} catch (error) { }

		if (noNeedProspector) {
			noNeedProspector = localStorage.getItem('noNeedProspector')
			noNeedProspector = noNeedProspector ? noNeedProspector : null;
		}

		if (!noNeedProspector) {
			try {
				noNeedProspector = (selectedCard.card_meta.contactFormStatus === "0") ? true : false;
			} catch (error) { }
		}
		// End of no need form section

		try {
			tempProspector = JSON.parse(localStorage.getItem('tempProspector'))

			tempProspector = tempProspector ? tempProspector : null;
		} catch (error) {
			// console.log(error);
		}

		return (
			<div>
				<Helmet> <title>{selectedCard.card_name}</title> </Helmet>

				<style>{".tab_bg{ background-color:#" + tabColor + " !important; }"}</style>
				<style>{".collapsible-header{ background-color:#" + tabColor + " !important; }"}</style>
				<style>{".single-tab.type-SMM{ background-color:#" + tabColor + " !important; }"}</style>
				<style>{".view-card .card-header{ background-color:#" + tabColor + " !important; }"}</style>
				<style>{".view-card .header-shape{ background-color:#" + tabColor + " !important; border-top: 100px solid #" + tabColor + " !important; }"}</style>

				<div className="App view-card">
					{ selectedCard.dynamicLink
						?
						// <CopyToClipboard text={selectedCard.dynamicLink}>
							<a className="download-bar" href={selectedCard.card_author} target={`_blank`}>
								<div className='download-text'>
									<div className={`describer`}>Create your card Now! Try it for Free!
									</div>
									<div>
										download {' '} <i className="fas fa-download"  aria-hidden="true"/>
									</div>
								</div>
								{ selectedCard.authorFree
								?<div className='info-area describer'>Upgrade to premium to remove ads banner</div>
								:''  }
							</a>
						// </CopyToClipboard>
						:''  }

					<div className="card-header">
						<div className="header-main">
							<div className="header-logo">
								<img src={logoImage} alt="SAP Logo" />
							</div>
						</div>
					</div>
					<div className="header-shape"></div>

					<div className="after-header"></div>

					<div className={`card-content ${hideCoverPhoto != true ? 'has-cover-photo' : 'not-cover-photo'} ${hideProfilePhoto != true ? 'has-profile-photo' : 'not-profile-photo'}`}>

						{(() => {
							if (!selectedCard.ID) {
								return (<div className="card-loading">Loading...</div>);
							} else {
								return (<div>

									<div className="header-area">
										{hideCoverPhoto != true && <div className={`profile-cover ${hideProfilePhoto != true ? 'has-profile-photo' : ''}`}>
											<img src={selectedCard.card_cover} alt={selectedCard.card_name} />
										</div>}

										{hideProfilePhoto != true && <div className="profile-photo">
											<img src={selectedCard.card_photo} alt={selectedCard.card_name} />
										</div>}
									</div>

									<div className="basic-area">
										{(() => {
											if (selectedCard.card_name) {
												return (
													<div className="info-name">{selectedCard.card_name}</div>
												)
											}
										})()}
										{(() => {
											if (selectedCard.card_title) {
												return (
													<div className="info-position">{selectedCard.card_title}</div>
												)
											}
										})()}
										{(() => {
											if (selectedCard.card_address) {
												return (
													<div className="info-location">{selectedCard.card_address}</div>
												)
											}
										})()}
									</div>

									{(() => {
										if (selectedCard.card_phone || selectedCard.card_text || selectedCard.card_email || (shareCardButton === true && navigator.share)) {
											return (
												<div className="action-area">
													{(() => {
														if (selectedCard.card_phone) {
															return (
																<span className="action-single"
																	  onClick={() => {
																	this.sendNotification(
																		{
																			"card": cardID,
																			"type": "custom",
																			"custom_title": "Tapped on call button",
																			"type_id": cardID,
																		}
																	)
																	window.open('tel:' + selectedCard.card_phone, '_self')
																}
																	  }>
																	<i className="fa fa-phone action-icon" aria-hidden="true"></i>
																	<div className="action-name">phone</div>
																</span>
															)
														}
													})()}
													{(() => {
														if (selectedCard.card_text) {
															return (
																<span className="action-single" onClick={() => {
																	this.sendNotification(
																		{
																			"card": cardID,
																			"type": "custom",
																			"custom_title": "Tapped on text button",
																			"type_id": cardID,
																		}
																	)
																	window.open('sms:' + selectedCard.card_text, '_self')
																}}>
																	<i className="fa fa-comment action-icon" aria-hidden="true"></i>
																	<div className="action-name">text</div>
																</span>)
														}
													})()}
													{(() => {
														if (selectedCard.card_email) {
															return (
																<span className="action-single" onClick={() => {
																	this.sendNotification(
																		{
																			"card": cardID,
																			"type": "custom",
																			"custom_title": "Tapped on mail button",
																			"type_id": cardID,
																		}
																	)
																	window.open('mailto:' + selectedCard.card_email)
																}}>
																	<i className="fa fa-envelope action-icon" aria-hidden="true"></i>
																		<div className="action-name">mail</div>
																</span>)
														}
													})()}

													{/* Start of download card */}

													<span className="action-single" onClick={() => {
														this.sendNotification(
															{
																"card": cardID,
																"type": "custom",
																"custom_title": "Tapped on download button",
																"type_id": cardID,
															}
														)
														window.open(`/download-vcf/${cardID}`, '_self')
														// this._downloadVCF(selectedCard)
													}}>
														<i className="fas fa-download action-icon" aria-hidden="true"></i>
														<div className="action-name">save</div>
													</span>
													{/* End of download card */}

													{/* Start of share card */}
													{(() => {
														if(shareCardButton === true && navigator.share){
															return (
																<span className="action-single" onClick={() => {

																	this.sendNotification(
																		{
																			"card": cardID,
																			"type": "shareFromWeb",
																			"shareWith": "shareFromWeb",
																			"type_id": cardID,
																		}
																	)

																	if (navigator.share) {
																		navigator.share({
																			title: selectedCard.card_name,
																			url: selectedCard.card_link
																		}).then(() => {
																			console.log('Thanks for sharing!');
																		})
																		.catch(console.error);
																	} else {
																		console.log('Share Not available..');
																	}
																}}>
																	<i className="fas fa-share action-icon" aria-hidden="true"></i>
																	<div className="action-name">share</div>
																</span>
															)
														}
													})()}
													{/* End of share card */}
												</div>
											)
										}
									})()}


									<div className="tabs-area">
										{
											selectedCard.tab ? selectedCard.tab.map(tabInfo => {
												if (tabInfo.tab_type == 1) {
													return <TabType1 key={tabInfo.TabID}
														tabInfo={tabInfo} />
												}
												if (tabInfo.tab_type == 2) {
													return <TabType2 key={tabInfo.TabID}
														tabInfo={tabInfo} />
												}
											}) : ''
										}

										{
											selectedCard.tab ? <TabTypeSMM socialMedia={selectedCard.social_media} /> : ''
										}
									</div>

								</div>);
							}
						})()}
					</div>

					{(() => {
						if (!tempProspector && !noNeedProspector && contactFormOpenDelay > 0) {
							// alert(contactFormOpenDelay);
							return <GuestForm wait={contactFormOpenDelay} />
							// setTimeout(function () {
							// }, contactFormOpenDelay);
						}
					})()}
				</div>
			</div>
		)
	}

	_downloadVCF = async (cardData) => {
		let imageFile;
		if (cardData.card_photo) {
			imageFile = await imageToBase64(cardData.card_photo);
		}
		// Get current card

		const myVCard = new VCard()
		// Some variables
		const lastname = ''
		const firstname = cardData.card_name
		const additional = ''
		const prefix = ''
		const suffix = ''

		myVCard
			// Add personal data
			.addName(lastname, firstname, additional, prefix, suffix)
			// Add work data
			.addCompany(cardData.card_address)
			.addJobtitle(cardData.card_title)
			.addEmail(cardData.card_email)
			.addPhoneNumber(cardData.card_phone, 'PREF;WORK')
			.addPhoneNumber(cardData.card_text, 'TEXT')
			.addURL(window.location.href)
			.addPhoto(imageFile)

		// myVCard.buildVCard()

		const element = document.createElement("a");
		const file = new Blob([myVCard.toString()], { type: 'text/x-vcard;charset=utf-8;' });
		element.href = URL.createObjectURL(file);
		element.download = `${cardData.card_name}.vcf`;
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	cardState: state.card,
})
const mapDispatchToProps = ({
	loadCardDetails,
	sendNotification,
	setPageData,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(cardDetails))