import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Collapsible from 'react-collapsible';


var QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;
const ReactQuill = require('react-quill');


const ref = React.createRef();

class ContentType1 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cardID: this.props.match.params.cardID,
			error: {},
		}
	}

	componentDidMount() {
		// Send can open notification //
	}
	render() {
		let { contentInfo } = this.props

		let { cardID } = this.state

		let html, contentHTML;

		try {
			contentHTML = contentInfo.contentHTML;

			// var deltaOps = contentInfo.contents.content;
			// var converter = new QuillDeltaToHtmlConverter(deltaOps, {
			// 	paragraphTag: 'div',
			// 	customTag: (op) => {
			// 		return ['bold'];
			// 	},
			// 	customCssClasses: (op) => {
			// 		let classes = []
			// 		if (op.attributes['b'] === true) {
			// 			classes = [...classes, 'text-bold']
			// 		}
			// 		return classes;
			// 	},
			// });
			// html = converter.convert();

		} catch (error) {

		}

		contentInfo.color = contentInfo.color ? `#${contentInfo.color}` : 'black';
		console.log('contentInfo.color');
		console.log(contentInfo.color);

		return (
			contentInfo.link
				? <a href={contentInfo.link}
					style={{ color: contentInfo.color }}
					target="_blank">
					<div className="custom-text"
						dangerouslySetInnerHTML={{
							__html: contentHTML
						}}>
					</div>
				</a>
				: <div className="custom-text" style={{ color: contentInfo.color }}
					dangerouslySetInnerHTML={{
						__html: contentHTML
					}}>
				</div>

		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
})
const mapDispatchToProps = ({
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentType1))
