// import axios from 'axios'
// import * as Types from './types'

const bodyClassController = (data) => dispatch => {
	let pageData = {}
	if (data !== undefined) {
		pageData = data
	}

	document.body.classList.remove('bg-primary');
	if (pageData.pageID === 'login') {
		document.body.classList.add('bg-primary');
	}
	if (pageData.pageID === 'registration') {
		document.body.classList.add('bg-primary');
	}
}

export default bodyClassController