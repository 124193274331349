import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";

import { createBrowserHistory } from 'history'

import App from './components/App';
import * as serviceWorker from './serviceWorker';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux'
import store from './store'
const history = createBrowserHistory();

ReactDOM.render(
	<Provider store={store}>
		<Router history={history} >
			<App />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<ToastContainer />
		</Router>
	</Provider>, document.getElementById('root'));

serviceWorker.unregister();
