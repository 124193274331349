import { combineReducers } from 'redux'
import authReducer from './authReducer'
import cardReducer from './cardReducer'
import prospectorReducer from './prospectorReducer'
import pageDataReducer from './pageDataReducer'

const rootReducer = combineReducers({
	auth: authReducer,
	card: cardReducer,
	prospector: prospectorReducer,
	pageData: pageDataReducer,
})

export default rootReducer