import React from 'react';

import '../assets/styles/base/styles.scss'


import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Home from '../pages/Home'

import cardDetails from '../pages/cardDetails'

import { connect } from 'react-redux'

import configureAxios from '../store/utils/configureAxios'




class App extends React.Component {
	state = {
		user: '',
		password: '',
		error: {},
	}
	componentWillMount() {
		configureAxios() // Run Basic Axios Configure

		const authToken = localStorage.getItem('authToken')
	}

	componentDidMount() {
	}

	render() {
		let { isAuthenticated } = this.props.auth
		return (
			<BrowserRouter>
				<Switch>
					<Route path="/" exact component={Home} />

					<Route path="/:cardID" exact component={cardDetails} />

				</Switch>
			</BrowserRouter>
		)
	}
}

// export default App;

const mapStateToProps = state => ({
	auth: state.auth
})
const mapDispatchToProps = ({
})
export default connect(mapStateToProps, mapDispatchToProps)(App)