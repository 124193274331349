import axios from 'axios';

const configureAxios = token => {
	axios.defaults.baseURL = 'https://ncp.prismict.com/api';
	// axios.defaults.baseURL = 'https://betancp.prismict.com/api';
	setAuthToken()
	return true
}


export const setAuthToken = async () => {
	axios.defaults.headers.common['X-Companyid'] = 101;
}
export default configureAxios