import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { sendNotification, setCardOpenTab } from '../../store/actions/cardActions'

const ref = React.createRef();

class TabType2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
		}
	}

	componentDidMount() {
	}
	render() {
		let { auth, tabInfo } = this.props

		return (
			<div className="single-tab type-2" id={`tab_id_${tabInfo.TabID}`}>
				<a href={tabInfo.tab_link} target="_blank" className="tab-link tab_bg"
					onClick={() => {
						this.props.sendNotification(
							{
								"card": tabInfo.CardID,
								"type": "tab",
								"type_id": tabInfo.TabID,
							}
						)
					}}>
					{tabInfo.tab_title}
				</a>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	cardState: state.card,
})
const mapDispatchToProps = ({
	sendNotification,
	setCardOpenTab,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabType2))
