import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

var QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;
const ReactQuill = require('react-quill'); // CommonJS


const ref = React.createRef();

class ContentType2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cardID: this.props.match.params.cardID,
			error: {},
		}
	}

	componentDidMount() {
		// Send can open notification //
	}
	render() {
		let { contentInfo } = this.props

		let { cardID } = this.state

		let photoLink;

		try {
			photoLink = contentInfo.photo;
		} catch (error) { }

		return (
			contentInfo.link
				? photoLink ? <a href={contentInfo.link} target="_blank"><div className="preview-photo"> <img src={photoLink} alt="" /> </div></a> : ''
				: photoLink ? <div className="preview-photo"> <img src={photoLink} alt="" /> </div> : ''

		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
})
const mapDispatchToProps = ({
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentType2))
