import * as Types from '../actions/types'

const init = {
	data: null,
	pagination: {},
	error: {},
	selectedCard: {},
	success: '',
	openTabID: null,
}

const cardReducer = (state = init, action) => {
	switch (action.type) {

		case Types.CARD_DETAILS: {
			return {
				...state,
				selectedCard: action.payload.cardDetails
			}
		}
		case Types.CARD_OPEN_TAB: {
			return {
				...state,
				openTabID: action.openTabID
			}
		}
		default: return state
	}
}

export default cardReducer