import * as Types from '../actions/types'

const init = {
	status: null,
	prospector: {},
	pagination: {},
	error: {},
	success: '',
}

const prospectorReducer = (state = init, action) => {
	switch (action.type) {

		case Types.PROS_DETAILS: {
			return {
				...state,
				status: 'ready',
				prospector: action.payload.tempProspector,
			}
		}
		default: return state
	}
}

export default prospectorReducer