export const SET_USER = 'SET_USER'
export const USERS_ERROR = 'USERS_ERROR'
export const USERS_AUTH_CHECKING = 'USERS_AUTH_CHECKING'

export const UPDATE_RESET = 'UPDATE_RESET'

export const CARD_DETAILS = 'CARD_DETAILS'
export const CARD_OPEN_TAB = 'CARD_OPEN_TAB'

export const PROS_DETAILS = 'PROS_DETAILS'

export const SET_PAGE_DATA = 'SET_PAGE_DATA'